import React from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import Slider from "react-slick";

import AppWrap from '../AppWrap';

import {cacheImage} from '../util';

import './style.scss';

class Home extends React.Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
    const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
    if (clientWidth < 1200) {
      this.handleResize(clientWidth);
    }

    window.addEventListener('resize', () => {
      const width = document.documentElement.clientWidth || document.body.clientWidth;
      this.handleResize(width >= 1200 ? 1200 : width);
    }, true);

    const slider = document.querySelector('.slider3')
    window.onscroll = () => {
      if (this.autoplay) {
        return;
      }
      const height = document.documentElement.clientHeight;
      const {top} = slider.getBoundingClientRect();
      if (top < height) { // 可见
        this.autoplay = true;
        this.slider.slickPlay();
      }
    }
    cacheImage(0);
  }

  handleResize(clientWidth) {
    const height = 550 / 1200 * clientWidth;
    const doms = document.querySelectorAll('.contact-item');
    doms.forEach(dom => {
      dom.style.height = `${height}px`;
    });

    const scale = height / 550;
    const doms2 = document.querySelectorAll('.contact2');
    doms2.forEach(dom => {
      dom.style.transform = `scale(${scale})`;
    });
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 400,
      autoplay: true,
      // slidesToShow: 1,
      // slidesToScroll: 1,
      dotsClass: "slick-dots slick-thumb",
    };

    return (
      <AppWrap>
        <Helmet>
          <link rel="stylesheet" type="text/css" charSet="utf-8" href="https://img.gouxiang.cn/of/slick1.6.0.min.css" />
          <link rel="stylesheet" type="text/css" href="https://img.gouxiang.cn/of/slick-theme1.6.0.min.css" />
        </Helmet>
        <div className="Home">
          <div className="home-wrap" style={{position: 'relative', textAlign: 'center'}}>
            <img src="https://img.gouxiang.cn/of/p1.jpeg" style={{width: '100%'}} />
            <div className="text">
              <div>用设计和数字技术</div>
              <div>为大众提升居住体验</div>
            </div>
          </div>
          <div className="links">
            <div className="link active">我们</div>
            <Link to="/business" title="业务"><div className="link">业务</div></Link>
            <Link to="/commitments" title="宗旨"><div className="link">宗旨</div></Link>
            <Link to="/insights" title="洞见"><div className="link">洞见</div></Link>
          </div>
          <div className="slider">
            <Slider {...settings}>
              <img src={require('../../images/p2.jpeg')} style={{width: '100%'}} />
              <img src={require('../../images/p22.jpeg')} style={{width: '100%'}} />
              <img src={require('../../images/p23.jpeg')} style={{width: '100%'}} />
            </Slider>
            <div className="text">
              <div>共创</div>
              <div>更丰富的品质生活</div>
            </div>
          </div>
          {/* <div style={{fontSize: 12, color: '#000', paddingTop: 40, lineHeight: '25px', letterSpacing: 3, marginTop: 6, paddingBottom: 40, backgroundColor: '#f3f3f3'}}>
            <div className="title">共创</div>
            <div className="title">更丰富的品质生活</div>
            <div style={{marginTop: 10}}>我们以用户需求为中心，</div>
            <div>以设计和数字技术赋能供应链，</div>
            <div>为大众提供质高价优，种类丰富，</div>
            <div>环境友好的产品。</div>
          </div> */}
          <div className="business">
            <div className="text">
              <div className="title">家居的齐整</div>
              <div className="title">是美好生活的开端</div>
              <div className="eng eng-first">A tidy and sweet home</div>
              <div className="eng">is the beginning of a better life</div>
              <Link to="/business" title="我们的业务" className="op">
                查看我们的业务 >
              </Link>
            </div>
            <img src={require('../../images/p3.jpeg')} style={{width: '100%'}} />
          </div>
          <div className="talent">
            <div className="title">和我们一起探索未来居住</div>
            <div className="eng eng-first">Together，</div>
            <div className="eng">let’s explore the future of living</div>
            <Link to="/talent" title="加入我们" className="op">
              加入我们 >
            </Link>
          </div>
          <img src={require('../../images/p4.jpeg')} style={{width: '100%'}} />
          <div className="purpose">
            <div className="title">让更多人住得更好</div>
            <div className="eng eng-first">Let more people live better</div>
            <Link to="/commitments" title="我们的宗旨" className="op">
              查看我们的宗旨 >
            </Link>
          </div>
          <div className="slider2">
            <Slider {...settings} dotsClass="slick-dots purpose-dots slick-thumb">
              <img src={require('../../images/p5.jpeg')} style={{width: '100%'}} />
              <img src={require('../../images/p52.jpeg')} style={{width: '100%'}} />
              <img src={require('../../images/p53.jpeg')} style={{width: '100%'}} />
            </Slider>
          </div>
          <div className="insight">
            <div className="text">
              <div>“ 我们需要帮助构建生态系统，</div>
              <div>让更多的人在未来拥有利益，</div>
              <div>不仅作为消费者受益，而且作为创造者受益。”</div>
            </div>
            <div style={{position: 'relative'}}>
              <img src={require('../../images/p6.jpeg')} style={{width: '100%'}} />
              <Link to="/insights" title="我们的洞见">
                <div className="insight-op">更多洞见 ></div>
              </Link>
            </div>
          </div>
          <Link to="/contact" title="联系我们" className="highlight">
            <img src={require('../../images/p7.jpeg')} style={{width: '100%'}} />
          </Link>
          <Link to="/partnerships" title="合作伙伴" className="highlight">
            <img src={require('../../images/p8.jpeg')} style={{width: '100%'}} />
          </Link>
          <Link to="/talent" title="加入我们" className="highlight">
            <img src={require('../../images/p9.jpeg')} style={{width: '100%'}} />
          </Link>
        </div>
        <div className="Home1">
          <div style={{lineHeight: 0, borderBottom: '1px solid rgba(163,163,163,0.5)', backgroundColor: '#000'}}>
            <div style={{position: 'relative', maxWidth: 1200, margin: '0 auto'}}>
              <img src={require('../../images/p1-1.jpeg')} style={{width: '100%', height: 700}} />
              <div style={{position: 'absolute', top: 110, left: 110, fontSize: 30, color: '#fff', lineHeight: '60px', letterSpacing: 3, textAlign: 'left'}}>
                <div>用设计和数字技术</div>
                <div>为大众提升居住体验</div>
              </div>
            </div>
          </div>
          <div style={{paddingTop: 15, lineHeight: 0, backgroundColor: '#000'}}>
            <div style={{position: 'relative', width: '100%', maxWidth: 1920, height: 700, margin: '0 auto'}}>
              <div className="slider slider-pc" style={{marginTop: 0}}>
                <Slider {...settings} className="my-slider">
                  <img src={require('../../images/p2-1.jpeg')} style={{width: '100%'}} />
                  <img src={require('../../images/p22-1.jpeg')} style={{width: '100%'}} />
                  <img src={require('../../images/p23-1.jpeg')} style={{width: '100%'}} />
                </Slider>
              </div>
              <div className="slider-text">
                <div>共创</div>
                <div>更丰富的品质生活</div>
              </div>
            </div>
          </div>
          {/* <div className="item">
            <div className="title">共创</div>
            <div className="title">更丰富的品质生活</div>
            <div style={{marginTop: 40}}>我们以用户需求为中心，以设计和数字技术赋能供应链，</div>
            <div>为大众提供质高价优，种类丰富，</div>
            <div>环境友好的产品。</div>
            <div style={{maxWidth: 1200, padding: '0 110px', boxSizing: 'border-box', margin: '65px auto 0', lineHeight: 0}}>
              <Slider {...settings}>
                <img src={require('../../images/p2-1.jpeg')} style={{width: '100%', maxWidth: 855}} />
                <img src={require('../../images/p2-1.jpeg')} style={{width: '100%', maxWidth: 855}} />
                <img src={require('../../images/p2-1.jpeg')} style={{width: '100%', maxWidth: 855}} />
              </Slider>
            </div>
          </div> */}
          <div className="item">
            <div className="title">家居的齐整</div>
            <div className="title">是美好生活的开端</div>
            <div className="eng" style={{fontSize: 22, letterSpacing: 1, lineHeight: '27px', color: '#262626', marginTop: 30}}>A tidy and sweet home</div>
            <div className="eng" style={{fontSize: 22, letterSpacing: 1, lineHeight: '27px', color: '#262626', marginTop: 5}}>is the beginning of a better life</div>
            <Link to="/business" title="我们的业务">
              <div className="op">查看我们的业务 ></div>
            </Link>
            <div style={{maxWidth: 1200, padding: '0 110px', boxSizing: 'border-box', margin: '65px auto 0', lineHeight: 0}}>
              <img src={require('../../images/p3-1.jpeg')} style={{width: '100%', maxWidth: 855}} />
            </div>
          </div>
          <div className="item">
            <div className="title">和我们一起探索未来居住</div>
            <div className="eng" style={{fontSize: 22, letterSpacing: 1, lineHeight: '27px', color: '#262626', marginTop: 30}}>Together，</div>
            <div className="eng" style={{fontSize: 22, letterSpacing: 1, lineHeight: '27px', color: '#262626', marginTop: 5}}>let’s explore the future of living</div>
            <Link to="/talent" title="加入我们">
              <div className="op">加入我们 ></div>
            </Link>
            <div style={{maxWidth: 1200, padding: '0 110px', boxSizing: 'border-box', margin: '65px auto 0', lineHeight: 0}}>
              <img src={require('../../images/p4-1.jpeg')} style={{width: '100%', maxWidth: 855}} />
            </div>
          </div>
          <div className="item">
            <div className="title">让更多人住得更好</div>
            <div className="eng" style={{fontSize: 22, letterSpacing: 1, lineHeight: '27px', color: '#262626', marginTop: 30}}>Let more people live better</div>
            <Link to="/commitments" title="我们的宗旨">
              <div className="op">查看我们的宗旨 ></div>
            </Link>
            <div className="slider3" style={{maxWidth: 1075, padding: '0 110px', boxSizing: 'border-box', margin: '65px auto 0', lineHeight: 0}}>
              <Slider ref={ref => (this.slider = ref)} {...settings} autoplay={false} className="my-slider">
                <img src={require('../../images/p5-1.jpeg')} style={{width: '100%'}} />
                <img src={require('../../images/p52-1.jpeg')} style={{width: '100%'}} />
                <img src={require('../../images/p53-1.jpeg')} style={{width: '100%'}} />
              </Slider>
            </div>
          </div>
          <div style={{position: 'relative', marginTop: 15, backgroundColor: '#000'}}>
            <div style={{maxWidth: 1200, padding: '0 110px', boxSizing: 'border-box', margin: '0 auto'}}>
              <div style={{position: 'absolute', top: '10%', left: 0, right: 0, fontSize: 26, color: '#fff', lineHeight: '51px', textAlign: 'center', letterSpacing: 3, zIndex: 1}}>
                <div>“ 我们需要帮助构建生态系统，</div>
                <div>让更多的人在未来拥有利益，</div>
                <div>不仅作为消费者受益，而且作为创造者受益。”</div>
              </div>
              <div style={{position: 'relative', textAlign: 'center'}}>
                <img src={require('../../images/p6-1.jpeg')} style={{width: '100%', maxWidth: 855}} />
                <Link to="/insights" title="我们的洞见">
                  <div className="op op-insight">更多洞见 ></div>
                </Link>
              </div>
            </div>
          </div>
          <Link to="/contact" title="联系我们">
            <div style={{width: '100%', marginTop: 15, backgroundColor: '#511b9f'}}>
              <div style={{position: 'relative', maxWidth: 1200, margin: '0 auto'}}>
                <div className="contact-item">
                  <div className="contact2">
                    <div style={{fontSize: 45, paddingTop: 105}}>构享官方联系方式</div>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: 65}}>
                      <div style={{fontSize: 32, lineHeight: '63px', borderBottom: '2px solid #fff'}}>联系我们</div>
                      <img src={require('../../images/arrow.png')} style={{width: 56, height: 43, marginTop: 18, marginLeft: 40}} />
                    </div>
                    <div className="eng" style={{fontSize: 38, opacity: 0.3, marginTop: 110}}>Contact Us</div>
                  </div>
                </div>
                <div className="scale" style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, textAlign: 'right'}}>
                  <img src={require('../../images/p7-11.png')} style={{height: '100%'}} />
                </div>
              </div>
            </div>
          </Link>
          <Link to="/partnerships" title="合作伙伴">
            <div style={{width: '100%', backgroundColor: '#e5e5e5'}}>
              <div style={{position: 'relative', maxWidth: 1200, margin: '0 auto'}}>
                <div className="contact-item" style={{width: '100%', height: 550, paddingRight: 110, boxSizing: 'border-box'}}>
                  <div className="contact2" style={{color: '#541c83', transformOrigin: 'right top'}}>
                    <div style={{fontSize: 45, textAlign: 'right', paddingTop: 105}}>成为构享的合作伙伴</div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 65}}>
                      <img src={require('../../images/arrow2.png')} style={{width: 56, height: 43, marginTop: 18, marginRight: 40}} />
                      <div style={{fontSize: 32, lineHeight: '63px', borderBottom: '2px solid #541c83'}}>合作咨询</div>
                    </div>
                    <div className="eng" style={{fontSize: 38, opacity: 0.3, textAlign: 'right', marginTop: 110}}>Business Cooperation</div>
                  </div>
                </div>
                <div className="scale" style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, textAlign: 'left'}}>
                  <img src={require('../../images/p8-11.png')} style={{height: '100%'}} />
                </div>
              </div>
            </div>
          </Link>
          <Link to="/talent" title="加入我们">
            <div style={{width: '100%', backgroundColor: '#333'}}>
              <div style={{position: 'relative', maxWidth: 1200, margin: '0 auto'}}>
                <div className="contact-item">
                  <div className="contact2">
                    <div style={{fontSize: 45, paddingTop: 105}}>获得在构享的工作机会</div>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: 65}}>
                      <div style={{fontSize: 32, lineHeight: '63px', borderBottom: '2px solid #fff'}}>加入我们</div>
                      <img src={require('../../images/arrow.png')} style={{width: 56, height: 43, marginTop: 18, marginLeft: 40}} />
                    </div>
                    <div className="eng" style={{fontSize: 38, opacity: 0.3, marginTop: 110}}>Join Us</div>
                  </div>
                </div>
                <div className="scale" style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, textAlign: 'right'}}>
                  <img src={require('../../images/p9-11.png')} style={{height: '100%'}} />
                </div>
              </div>
            </div>
          </Link>
        </div>
      </AppWrap>
    );
  }
}

export default Home;
