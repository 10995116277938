import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Home from './Home';
import Business from './Business';
import Purpose from './Purpose';
import Insight from './Insight';
import About from './About';
import Investor from './Investor';
import Talent from './Talent';
import Partner from './Partner';
import './App.scss';

const App = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/business" element={<Business />} />
    <Route path="/commitments" element={<Purpose />} />
    <Route path="/insights" element={<Insight />} />
    <Route path="/contact" element={<About />} />
    <Route path="/investors" element={<Investor />} />
    <Route path="/talent" element={<Talent />} />
    <Route path="/partnerships" element={<Partner />} />
  </Routes>
);

export default App;
