export function cacheImage(current) {
  const imgs = [
    'https://img.gouxiang.cn/of/p1.jpeg',
    'https://img.gouxiang.cn/of/h0.jpeg',
    'https://img.gouxiang.cn/of/x0.jpeg',
    'https://img.gouxiang.cn/of/i1.jpeg',
  ];
  imgs.forEach((url, key) => {
    if (current !== key) {
      const img = new Image();
      img.src = url;
    }
  })
}