import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { isAndroid, isIphone } from '../util';
import './style.scss';


const navList = [{
  title: '首页',
  href: '/',
}, {
  title: '业务',
  href: '/business',
}, {
  title: '宗旨',
  href: '/commitments',
}, {
  title: '洞见',
  href: '/insights',
}];

class Head extends React.Component {

  state = {
    show: false,
    activePath: '',
  }

  componentDidMount() {
    const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
    if (clientWidth > 700) {
      return
    }
    this.sTop = 0;
    this.diff = 0;
    this.timer = setTimeout(() => {
      this.diff = 0;
    }, 100);
    const height = clientWidth / 375 * 70;
    const menu = document.querySelector('.menu');
    menu.style.transform = `translateY(-50%) scale(${clientWidth/375})`;
    window.onscroll = function () {
      clearTimeout(this.timer);
      const menuShow = document.querySelector('.menu-list-show')
      if (menuShow) {
        return;
      }
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop < 0) {
        return
      }
      // console.log('===', scrollTop);
      if (this.sTop === 0) {
        this.sTop = scrollTop;
        return
      }
      const dom = document.querySelector('.head-wrap')
      if (scrollTop > this.sTop) {
        // 上滑
        // dom.classList.add('hide')
        this.diff += scrollTop - this.sTop;
        if (this.diff > height) {
          dom.style.transform = `translateY(${-height}px)`;
        } else {
          dom.style.transform = `translateY(${-this.diff}px)`;
        }
        // console.log('===', this.diff)
      } else {
        this.sTop = 0;
        this.diff = 0;
        dom.style.transform = 'translateY(0)'
        // dom.classList.remove('hide')
      }
      this.sTop = scrollTop;
    }

    window.addEventListener('resize', () => {
      const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
      if (clientWidth > 700) {
        return
      }
      const height = clientWidth / 375 * 70;
      const menu = document.querySelector('.menu');
      menu.style.transform = `translateY(-50%) scale(${clientWidth/375})`;
    }, true);
  }

  handleClickMenu = () => {
    const show = !this.state.show;
    this.setState({
      show,
    })
  }

  handleOpen = (url = '') => {
    window.location.href = url;
  }

  render() {
    const { show, activePath, style={} } = this.state;

    return (
      <header className="head-wrap" style={style}>
        <div className="head">
          <div className={`menu ${show ? 'menu-show' : ''}`} onClick={this.handleClickMenu}>
            <div className="w-t">
              <div className="l-t" />
            </div>
            <div className="w-b">
              <div className="l-b" />
            </div>
          </div>
          <div className="logo">
            <Link to="/" onClick={() => this.setState({ show: false })} title="构享">
              <img src={require('../../images/logo.png')} className="logo-img"  />
            </Link>
          </div>
          <div className="nav-list">
            {
              navList.map((item, index) => {
                return (
                  <NavLink key={index} to={item.href} className={({ isActive }) => isActive ? "nav-item nav-item-act" : "nav-item"} title={item.title}>{item.title}</NavLink>
                )
              })
            }
          </div>
        </div>
        <div className={`menu-list ${show ? 'menu-list-show' : ''}`} onClick={this.handleClickMenu}>
          {
            navList.map((item, index) => {
              return (
                <NavLink key={index} to={item.href} className="menu-item" onClick={this.handleClickMenu} title={item.title}>{item.title}</NavLink>
              )
            })
          }
        </div>
      </header>
    );
  }
}

export default Head;
