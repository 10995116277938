import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './style.scss';

class Head extends React.Component {
  handleClick() {
    const dom = document.querySelector('.head-wrap');
    dom.style.transition = 'none';
    dom.style.transform = 'translateY(0)';
    window.scrollTo(0, 0);
    dom.style.transition = 'transform 0.3s ease-out';
  }

  handleClick2() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="footer-wrap">
        <div className="footer">
          <div className="foot-line" />
          <div className="link-wrap">
            <div className="more-info">公司的更多信息</div>
            <Link to="/contact" className="link-item"  title="联系我们" onClick={this.handleClick2}>联系我们</Link>
            <Link to="/investors" className="link-item" title="投资者关系" onClick={this.handleClick2}>投资者关系</Link>
            <Link to="/partnerships" className="link-item" title="合作伙伴" onClick={this.handleClick2}>合作伙伴</Link>
            <Link to="/talent" className="link-item" title="招贤纳士" onClick={this.handleClick2}>招贤纳士</Link>
          </div>
          <Link to="/">
            <div className="logo2" onClick={this.handleClick} />
          </Link>
          <div className="copyright">
            <div>© 2022 上海构享科技服务有限公司。版权所有。</div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <img src={require('../../images/gov.png')} style={{width: 13, height: 13, marginRight: 5}} />
              <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014391" style={{ color: '#787878', textDecoration: 'none', marginRight: 5 }} rel="nofollow">沪公网安备 31011202014391号</a>
              <a target="_blank" href="https://icp.chinaz.com/info?q=gouxiang.cn" style={{ color: '#787878', textDecoration: 'none' }} rel="nofollow">沪ICP备2022022320号</a>
            </div>
            {/* <div>违法和不良信息举报电话：4001001111-9 违法和不良信息举报邮箱：jubao@gouxiang.com</div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Head;
