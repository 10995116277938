import React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import AppWrap from '../AppWrap'

import './style.scss';

class Home extends React.Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <AppWrap>
        <Helmet>
          <meta name="keywords" content="构享，联系构享" />
          <meta name="description" content="通过电话、邮件与构享联系" />
        </Helmet>
        <div className="About">
          <div className="about-wrap">
            <div className="title">您好，需要什么帮助？</div>
            <div className="desc">有疑问或报告构享产品/服务存在的问题？</div>
            <div className="p400 eng">请拨打400热线电话</div>
            <div className="priColor help">
              <img src={require('../../images/phone.png')} className="icon" />
              <div className="phone eng">400-1111-360</div>
            </div>
            <div className="mail-title">或发送邮件与我们联系</div>
            <div className="priColor help">
              <img src={require('../../images/mail.png')} className="icon" />
              <div className="mail eng">help@gouxiang.cn</div>
            </div>
            <div className="line" />

            <div className="title">新闻</div>
            <div className="desc">若您是新闻媒体从业人员，可以发送邮件与我们联系</div>
            <div className="press priColor">
              <img src={require('../../images/mail.png')} className="icon" />
              <div className="mail eng">press@gouxiang.cn</div>
            </div>

            <div className="talent-title">招贤纳士</div>
            <div className="desc">了解我们的团队和空缺职位</div>
            <Link to="/talent" title="点击进入招聘页面" className="talent">
              <span>点击进入招聘页面</span>
            </Link>
            <div className="line" />

            <div className="title">关注我们</div>
            <div className="desc">在微信搜索并关注我们的服务号“构享生活”，</div>
            <div className="desc">获取更多资讯和服务。</div>

            <div style={{lineHeight: 0}}>
              <img className="gzh" src={require('../../images/gzh.png')} />
            </div>
          </div>
        </div>
      </AppWrap>
    );
  }
}

export default Home;
