import React from 'react';
import {Helmet} from "react-helmet";

import AppWrap from '../AppWrap'

import './style.scss';

class Home extends React.Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <AppWrap>
        <Helmet>
          <meta name="keywords" content="构享，投资构享" />
          <meta name="description" content="若您有意向投资构享，欢迎致电或邮件与我们联系" />
        </Helmet>
        <div className="Investor">
          <div className="inv-wrap">
            <div className="title">您好，若您有意向投资构享，</div>
            <div className="title">欢迎致电洽谈:</div>
            <div className="priColor wrap">
              <img src={require('../../images/phone2.png')} className="icon" />
              <span className="phone2 eng">+86 21 3478 1678</span>
            </div>
            <div className="contact">也可发送邮件与我们联系:</div>
            <div className="priColor wrap">
              <img src={require('../../images/mail.png')} className="icon" />
              <span className="mail eng">cooperation@gouxiang.cn</span>
            </div>

            <div className="pic">
              <img src={require('../../images/b1.jpeg')} style={{width: '100%', maxWidth: 656}} />
            </div>
          </div>
        </div>
      </AppWrap>
    );
  }
}

export default Home;
