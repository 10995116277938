import React from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import AppWrap from '../AppWrap'

import {cacheImage} from '../util';

import './style.scss';

class Purpose extends React.Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
    cacheImage(2);
  }
  render() {
    return (
      <AppWrap>
        <Helmet>
          <meta name="keywords" content="构享宗旨，居住服务体验，居住服务平台" />
          <meta name="description" content="构享致力于成为中国领先的居住服务平台，让更多人住得更好。" />
        </Helmet>
        <div className="Purpose">
          <div className="pur-head" style={{position: 'relative'}}>
            <img className="img" src="https://img.gouxiang.cn/of/x0.jpeg" style={{width: '100%'}} />
            <img className="img-pc" src={require('../../images/x0-1.jpeg')} style={{width: '100%'}} />
            <div className="text">
              <div>构享致力于</div>
              <div>成为中国领先的居住服务平台，</div>
              <div>让更多人住得更好。</div>
            </div>
          </div>
          <div className="links">
            <Link to="/" title="我们"><div className="link">我们</div></Link>
            <Link to="/business" title="业务"><div className="link">业务</div></Link>
            <div className="link active">宗旨</div>
            <Link to="/insights" title="洞见"><div className="link">洞见</div></Link>
          </div>

          <div className="item">
            <div className="desc">
              <div>被种草了空气炸锅，空气净化器、投影仪……</div>
              <div>除了攒钱等双十一下单，</div>
              <div>还有其他办法吗？</div>
            </div>
            <div>我们希望让更高品质的产品体验进入千家万户，</div>
            <div>无论是在家里，还是在长租公寓里。</div>
            <div className="img">
              <img src={require('../../images/x1.jpeg')} style={{width: '100%'}} />
              <div style={{height: 2}} />
              <img src={require('../../images/x2.jpeg')} style={{width: '100%'}}  />
              <div style={{height: 2}} />
              <img src={require('../../images/x3.jpeg')} style={{width: '100%'}}  />
            </div>
            <div className="img-pc">
              <img src={require('../../images/x1-1.jpeg')} style={{width: '100%'}} />
            </div>
          </div>

          <div className="item">
            <div className="desc">
              <div>精巧绝伦的设计、与众不同的腔调、可再生的材质……</div>
              <div>这些不应只是昂贵产品的标签。</div>
            </div>
            <div>我们重新设计家居产品的内在结构、供给方式……</div>
            <div>让你想用就用，</div>
            <div>让产品具有更长的生命周期，让地球的负担更轻。</div>
            <div className="img">
              <img src={require('../../images/x4.jpeg')} style={{width: '100%'}} />
            </div>
            <div className="img-pc">
              <img src={require('../../images/x4-1.jpeg')} style={{width: '100%'}} />
            </div>
          </div>

          <div className="item">
            <div style={{color: '#231815'}}>
              <div>在纠结是否为刚租下的房子添置冰箱？</div>
              <div>设想一下，一年半之后，</div>
              <div>当你不再需要，它被免费上门回购，</div>
              <div>一半的购置费用返还到你的钱包。</div>
              <div>这样一来，是不是更容易决策了？</div>
            </div>
            <div className="img">
              <img src={require('../../images/x5.jpeg')} style={{width: '100%'}} />
            </div>
            <div className="img-pc">
              <img src={require('../../images/x5-1.jpeg')} style={{width: '100%'}} />
            </div>
          </div>

          <div className="item">
            <div style={{color: '#231815'}}>
              <div>在未来，完善的数字系统和供应链体系将充分开放。</div>
              <div>无论在一二线还是三四线城市，</div>
              <div>你都可以轻松享受更高品质的服务。</div>
            </div>
            <div className="img">
              <img src={require('../../images/x6.jpeg')} style={{width: '100%'}} />
            </div>
            <div className="img-pc">
              <img src={require('../../images/x6-1.jpeg')} style={{width: '100%'}} />
            </div>
          </div>

          <div className="item">
            <div style={{color: '#231815'}}>
              <div>真正懂得用户需求的，</div>
              <div>只有用户自己。</div>
              <div>你的奇思妙想，</div>
              <div>应当被尊重和被实现。</div>
            </div>
            <div className="img">
              <img src={require('../../images/x7.jpeg')} style={{width: '100%'}} />
            </div>
            <div className="img-pc">
              <img src={require('../../images/x7-1.jpeg')} style={{width: '100%'}} />
            </div>
          </div>

          <div className="item">
            <div style={{color: '#231815'}}>
              <div className="title">更友好的城市，更自由的迁徙</div>
              <div>去想去的地方，拥抱未知和希望。</div>
              <div>偌大城市，容得下野心和彷徨。</div>
              <div>且将疲惫安放，笑忘了过往。</div>
              <div>致敬自由，致敬年轻，致敬远方。</div>
              <div>此心安处，便是吾乡。</div>
            </div>
            <div className="img">
              <img src={require('../../images/x8.jpeg')} style={{width: '100%'}} />
            </div>
            <div className="img-pc">
              <img src={require('../../images/x8-1.jpeg')} style={{width: '100%'}} />
            </div>
          </div>

          <div className="item">
            <div style={{color: '#231815'}}>
              <div className="title">更优质的居住服务，更温暖的体验</div>
              <div>把身心投入热爱。</div>
              <div>把琐事交给专家。</div>
              <div>把生活还给自己。</div>
              <div>用一种被懂得的舒适感，成就家一般的温暖。</div>
              <div>成就更美好的居住产业。</div>
            </div>
            <div className="img">
              <img src={require('../../images/x9.jpeg')} style={{width: '100%'}} />
            </div>
            <div className="img-pc">
              <img src={require('../../images/x9-1.jpeg')} style={{width: '100%'}} />
            </div>
          </div>

          <div className="item">
            <div style={{color: '#231815'}}>
              <div className="title">更美好的环境，更可持续发展的地球</div>
              <div>我们少用一点，</div>
              <div>给子孙后代多留一点资源。</div>
              <div>我们多做一点，</div>
              <div>给人类的未来多留一份从容。</div>
              <div>做好手边的事情，</div>
              <div>我们的世界，我们一起守护。</div>
            </div>
            <div className="img">
              <img src={require('../../images/x10.jpeg')} style={{width: '100%'}} />
            </div>
            <div className="img-pc">
              <img src={require('../../images/x10-1.jpeg')} style={{width: '100%'}} />
            </div>
          </div>
          <div className="img-end">
            <img src={require('../../images/t5.jpeg')} style={{width: '100%'}} />
          </div>
        </div>
      </AppWrap>
    );
  }
}

export default Purpose;
