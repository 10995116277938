import React from 'react';
import {Helmet} from "react-helmet";

import AppWrap from '../AppWrap'

import './style.scss';

const talents = ['空间设计专家', 'Industrial Design Specialist', '城市生活方式研究员', '用户需求分析师', 'Data Scientist', 'Digital Technical Support Engineer'];

class Home extends React.Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <AppWrap>
        <Helmet>
          <meta name="keywords" content="构享，构享招聘" />
          <meta name="description" content="构享诚邀您加入构享，构享致力于成为中国领先的居住服务平台，让更多人住得更好。" />
        </Helmet>
        <div className="Talent">
          <div className="talent-wrap">
            <div className="talent-head">
              <div className="title">构享诚邀您加入。</div>
              <div className="desc">
                <div>构享致力于成为中国领先的居住服务平台，</div>
                <div>让更多人住得更好。</div>
              </div>
              <img className="img" src={require('../../images/t1.jpeg')} style={{width: '100%'}} />
              <img className="img-pc" src={require('../../images/t1-1.jpeg')} style={{width: '100%'}} />
            </div>
            <div className="text">
              <div>我们相信</div>
              <div>只有疯狂到以为自己能够改变世界的人，才能真正改变世界。我们是一个年轻有活力的团队，底层逻辑和远景规划清晰，执行力强大，对未来的发展充满信心。欢迎有思想、才华出众、不安于现状、无惧挑战的你加入，一起成长，一起引领居住服务行业的变革，共创更友好的城市，推动可持续发展。</div>
            </div>
            <img src={require('../../images/t2.jpeg')} className="pic2" />
            <div className="text">
              <div>我们相信</div>
              <div>平等、包容、开放的环境能够更好地激发每个人的能量。我们尊重、欣赏、支持你的独特才华和性格。我们倡导高效协作、开放式沟通，拒绝官僚作风。不论性别、民族、年龄或背景，每个人都有机会在构享取得成功。</div>
            </div>
            <img src={require('../../images/t3.jpeg')} className="pic" />
            <div className="text">
              <div>我们相信</div>
              <div>员工的成长和组织的成长相辅相成。我们提供了多样化的成长路径，职业目标、晋升方式都由你自己决定。只有持续地吸纳新知，才能保持竞争力，无惧环境的变化和新的挑战。因此，我们践行长期主义，倡导终身学习，并为此大力投入，创造环境和便利。</div>
            </div>
            <img src={require('../../images/t4.jpeg')} className="pic3" />
          </div>

          <div className="pic5" style={{lineHeight: 0, marginTop: 60}}>
            <img src={require('../../images/t5.jpeg')} style={{width: '100%'}} />
          </div>

          <div className="position-wrap">
            <div className="positions">
              <div className="p-head">职位列表</div>
              {talents.map((title, key) => {
                return (
                  <div key={key} className="position">
                    <div className="desc1 eng">Position 职位</div>
                    <div className="priColor title eng">{title}</div>
                    <div className="desc2 eng">Location 工作地点</div>
                    <div className="city">上海</div>
                  </div>
                );
              })}
              <div className="send-wrap">
                <div className="send-title">欢迎投递简历</div>
                <div className="send-desc">
                  <div>若您对以上职位感兴趣，可以发送电子邮件</div>
                  <div>至以下地址与我们联系</div>
                </div>
                <div className="resume priColor">
                  <img src={require('../../images/mail.png')} className="icon" />
                  <span className="eng" style={{lineHeight: '25px'}}>resume@gouxiang.cn</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppWrap>
    );
  }
}

export default Home;
