import React from 'react';
import {Helmet} from "react-helmet";

import AppWrap from '../AppWrap'

import './style.scss';

class Home extends React.Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <AppWrap>
        <Helmet>
          <meta name="keywords" content="构享，构享商业合作，构享合作伙伴" />
          <meta name="description" content="构享的愿景是让更多的人住得更好，为此，我们以用户需求为中心，以设计和数字技术赋能供应链，为大众提供质高价优，种类丰富，环境友好的产品。" />
        </Helmet>
        <div className="Partner">
          <div className="part-header">
            <div className="title">您好，若您有商业合作需求，</div>
            <div className="title">欢迎致电:</div>
            <div className="phone">
              <img src={require('../../images/phone2.png')} className="icon" />
              <span className="eng">+86 21 3478 1678</span>
            </div>
            <div className="mail-title">也可发送邮件与我们联系:</div>
            <div className="phone">
              <img src={require('../../images/mail.png')} className="icon" />
              <span className="mail eng">cooperation@gouxiang.cn</span>
            </div>

            <div className="img-mobile" style={{marginTop: 50}}>
              <img src={require('../../images/pa1.jpeg')} style={{width: '100%'}} />
            </div>
            <div className="img-pc" style={{marginTop: 50}}>
              <img src={require('../../images/pa1.jpeg')} style={{width: '100%', maxWidth: 656}} />
            </div>
          </div>

          <div className="partner-wrap">
            <div className="partner-title">
              <div className="title2">构享的合作伙伴</div>
              <img className="img" src={require('../../images/pa2.jpeg')} />
              <img className="img-pc" src={require('../../images/pa2-1.jpeg')} />
            </div>
          </div>

          <div className="policy-wrap">
            <div className="title2">供应商政策</div>
            <div className="desc">构享的愿景是让更多的人住得更好，为此，我们以用户需求为中心，以设计和数字技术赋能供应链，为大众提供质高价优，种类丰富，环境友好的产品。</div>
            <div className="policy first">
              <div className="title eng">O 1</div>
              <div className="title priColor">平等对待，长期合作，共同发展</div>
              <img src={require('../../images/pa3.png')} className="icon" />
              <div>构享将供应商视为合作伙伴，</div>
              <div>而不是简单的买卖方关系。</div>
              <div>我们平等对待业务价值链的每一个环节，</div>
              <div>希望建立起彼此信任，长期稳定，合作共赢的关系。</div>
              <div>让我们一起持续努力，为更多人提升生活体验。</div>
            </div>

            <div className="policy">
              <div className="title eng">O 2</div>
              <div className="title priColor">开放赋能，支持成长</div>
              <img src={require('../../images/pa4.png')} className="icon" />
              <div>构享将逐步构建生态系统，</div>
              <div>以强大的设计能力和数字技术，</div>
              <div>为供应商的运营和成长提供全方位的支持，</div>
              <div>帮助供应商取得更大的成功。</div>
            </div>

            <div className="policy">
              <div className="title eng">O 3</div>
              <div className="title priColor">清正廉洁，禁止馈赠</div>
              <img src={require('../../images/pa5.png')} className="icon" />
              <div>构享绝对禁止员工接受与构享有任何业务往来的</div>
              <div>供应商、客户或服务机构的馈赠。</div>
              <div>所谓馈赠是指礼品、资金、旅行、餐饮、住宿之招待</div>
              <div>或其他特别好处。</div>
            </div>
          </div>
        </div>
      </AppWrap>
    );
  }
}

export default Home;
