import React, { Component } from 'react';
import {Helmet} from "react-helmet";

import Footer from './footer';
import Head from './head';
import './style.scss';

class App extends Component {

  render() {
    return [
      <Helmet key="header">
        <title>构享官方网站</title>
        <meta name="keywords" content="构享，家居用品体验，保洁，维修，收纳整理，搬家，公寓租赁" />
        <meta name="description" content="构享的使命使用设计和数字技术，重构居住空间之物及其供给，为大众提升居住体验。" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no, minimum-scale=1, maximum-scale=1" />
      </Helmet>,
      <Head key="head" {...this.props} />,
      this.props.children,
      <Footer key="footer" />,
    ]
  }
}

export default App;
