import React from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import AppWrap from '../AppWrap'

import {cacheImage} from '../util';

import './style.scss';

class Insight extends React.Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
    cacheImage(3);
  }
  render() {
    return (
      <AppWrap>
        <Helmet>
          <meta name="keywords" content="构享，数字技术" />
          <meta name="description" content="未来，人类会更自由地“放飞”，同时需要更安全的生活。人们在物理世界自由流动，并开拓数字世界，甚至飞向外太空；这就需要对自然环境的保护、数字技术的升级和太空技术的进步" />
        </Helmet>
        <div className="Insight">
          <div className="insight-wrap" style={{position: 'relative'}}>
            <img className="img" src="https://img.gouxiang.cn/of/i1.jpeg" style={{width: '100%'}} />
            <img className="img-pc" src={require('../../images/i1-1.jpeg')} style={{width: '100%'}} />
            <div className="head-title">
              <div>构享加大在数字世界方面的投入，</div>
              <div>期望不止帮助人们提升物质生活体验，更自由地流动，</div>
              <div>更成为人们精神空间的良伴。</div>
            </div>
            <div className="head-title-pc">
              <div>
                <div>构享加大在数字世界方面的投入，</div>
                <div>期望不止帮助人们提升物质生活体验，</div>
                <div>更自由地流动，</div>
                <div>更成为人们精神空间的良伴。</div>
              </div>
            </div>
          </div>
          <div className="links">
            <Link to="/" title="我们"><div className="link">我们</div></Link>
            <Link to="/business" title="业务"><div className="link">业务</div></Link>
            <Link to="/commitments" title="宗旨"><div className="link">宗旨</div></Link>
            <div className="link active">洞见</div>
          </div>

          <div className="item">
            <div className="head">
              <div className="line2" />
              <img src={require('../../images/eye.png')} className="icon" />
              <div className="line2" />
            </div>
            <div className="title eng">Insight. 01</div>
            <div className="desc desc2">
              <div>数据信息传输和处理技术的发展，</div>
              <div>将对人类社会的旧秩序产生重大影响。</div>
              <div style={{whiteSpace: 'nowrap'}}>国与国的界限趋向模糊，网络安全、信息安全、资金安全</div>
              <div>成为新时代的重要关注点。</div>
              <div className="eng">大数据、云计算、VR/AR和人工智能……</div>
              <div style={{whiteSpace: 'nowrap'}}>数字世界和物质世界的交融，将成就新人类的生活方式。</div>
            </div>
            <img className="img" src={require('../../images/i2.jpeg')} style={{width: '100%'}} />
            <div className="img-pc">
              <img src={require('../../images/i2-1.jpeg')} style={{width: '100%'}} />
              <div className="text">
                <div>构享加大在数字世界方面的投入，</div>
                <div>期望不止帮助人们</div>
                <div>提升物质生活体验，</div>
                <div>更自由地流动，</div>
                <div>更成为人们精神空间的良伴。</div>
              </div>
            </div>
            <div className="desc">
              <div>“最重要的是，我们需要帮助构建生态系统，</div>
              <div>让更多的人在未来拥有利益，</div>
              <div>不仅作为消费者受益，而且作为创造者受益。”</div>
            </div>
            <img src={require('../../images/pay.png')} className="icon2" />
            <div className="desc m25">
              <div className="eng">“VR/AR产业在2030将为全球增加1.5万亿美元的市场。</div>
              <div className="eng">VR 和 AR 的优势包括改进培训、</div>
              <div>降低风险和加快产品设计和交付。”</div>
            </div>
            <img src={require('../../images/vr.png')} className="icon2" />
          </div>

          <div className="item">
            <div className="head">
              <div className="line2" />
              <img src={require('../../images/eye.png')} className="icon" />
              <div className="line2" />
            </div>
            <div className="title eng">Insight. 02</div>
            <div className="desc desc2">
              <div>当物质世界的权力格局趋于稳定，</div>
              <div>正在数字世界野蛮拓荒的人类文明，</div>
              <div>开始重视曾被忽视和伤害的自然环境。</div>
              <div>对物质世界的保护，</div>
              <div>对可持续发展的追求，</div>
              <div>逐步成为全体人类的共识。</div>
            </div>
            <img className="img" src={require('../../images/i3.jpeg')} style={{width: '100%'}} />
            <div className="img-pc">
              <img src={require('../../images/i3-1.jpeg')} style={{width: '100%'}} />
              <div className="text" style={{color: '#541f7e', left: 22, right: 'initial'}}>
                <div>构享使用可再生、无污染的材料，</div>
                <div>并努力推动家居产品的循环利用，</div>
                <div>为人类文明的可持续发展</div>
                <div>做出应有的贡献。</div>
              </div>
            </div>
            <div className="desc">
              <div>“不违农时，谷不可胜食也；</div>
              <div>数罟不入洿池，鱼鳖不可胜食也；</div>
              <div>斧斤以时入山林，材木不可胜用也。”</div>
            </div>
            <img src={require('../../images/earth.png')} className="icon2" />
            <div className="desc m25">
              <div>“包括土地、水、生物多样性等在内的‘自然资本’</div>
              <div>正在迅速枯竭，</div>
              <div>一系列自然灾害造成的损失越来越大，</div>
              <div>需要我们换一种方式思考自然的价值。”</div>
            </div>
            <img src={require('../../images/tip.png')} className="icon2" />
          </div>

          <div className="item">
            <div className="head">
              <div className="line2" />
              <img src={require('../../images/eye.png')} className="icon" />
              <div className="line2" />
            </div>
            <div className="title eng">Insight. 03</div>
            <div className="desc desc2">
              <div>数字世界的竞争中，</div>
              <div>科学技术的比拼将是重中之重。</div>
              <div className="eng">AI、5G等是国家发展战略，</div>
              <div>也是更多企业研究的重要方向。</div>
            </div>
            <img className="img" src={require('../../images/i4.jpeg')} style={{width: '100%'}} />
            <div className="img-pc">
              <img src={require('../../images/i4-1.jpeg')} style={{width: '100%'}} />
              <div className="text" style={{right: 70}}>
                <div>数字技术</div>
                <div>是构享的核心驱动力之一。</div>
                <div>我们以智能化的产品</div>
                <div>帮助人们提升生活体验；</div>
                <div>以数字系统的逐步开放，</div>
                <div>赋能家居产业，</div>
                <div>推动行业升级。</div>
              </div>
            </div>
            <div className="desc">
              <div>“应该鼓励对社会明显有益的人工智能应用，</div>
              <div style={{whiteSpace: 'nowrap'}}>将人工智能研究和创业的力量引导到对社会有益的应用。”</div>
            </div>
            <img src={require('../../images/head.png')} className="icon2" />
            <div className="desc m25">
              <div style={{whiteSpace: 'nowrap'}}>“我觉得AI可能会主导世界，AI可能会最终决定谁来主导。</div>
              <div>我们需要打造一个可持续发展社会，</div>
              <div>要实现这一点，不要小修小补，</div>
              <div style={{whiteSpace: 'nowrap', letterSpacing: -1}}>需要彻底变革，包括生物技术、纳米技术、AI、物联网技术等，</div>
              <div>只有综合运用才能创造未来。”</div>
            </div>
            <img src={require('../../images/head2.png')} className="icon2" />
          </div>

          <div className="item">
            <div className="head">
              <div className="line2" />
              <img src={require('../../images/eye.png')} className="icon" />
              <div className="line2" />
            </div>
            <div className="title eng">Insight. 04</div>
            <div className="desc desc2">
              <div>人类的未来，是星辰大海。</div>
              <div>飞向太空，是梦想和希望的必经之路。</div>
            </div>
            <img className="img" src={require('../../images/i5.jpeg')} style={{width: '100%'}} />
            <div className="img-pc">
              <img src={require('../../images/i5-1.jpeg')} style={{width: '100%'}} />
              <div className="text" style={{left: 20, right: 'initial'}}>
                <div>构享将持续关注人类的飞天之旅，</div>
                <div>探索如何帮助人类</div>
                <div>在地球之外居住得更好。</div>
              </div>
            </div>
            <div className="desc">
              <div>“我们想在火星定居，长久地生存在那儿，</div>
              <div>我认为人类需要成为一个多星球定居的物种。”</div>
            </div>
            <img src={require('../../images/earth2.png')} className="icon2" />
            <div className="desc m25">
              <div>“几个世纪之后，也许将有许多人在太空出生，</div>
              <div>那里就是他们的第一个家。”</div>
            </div>
            <img src={require('../../images/bot.png')} className="icon2" />
          </div>
        </div>
      </AppWrap>
    );
  }
}

export default Insight;
