import React from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import AppWrap from '../AppWrap'

import {cacheImage} from '../util';

import './style.scss';

class Business extends React.Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
    cacheImage(1);
  }
  render() {
    return (
      <AppWrap>
        <Helmet>
          <meta name="keywords" content="构享业务，家居用品体验，保洁，维修，收纳整理，搬家，公寓租赁，全屋整装，房屋翻新，家居配置，房屋委托装修，出租，租务管理" />
          <meta name="description" content="提供高品质的居住生活服务，包含家居用品体验、保洁、维修、收纳整理、搬家、公寓租赁、全屋整装、翻新、家居配置、房屋委托装修、出租、租务管理等一站式资产管理服务。" />
        </Helmet>
        <div className="Business">
          <div className="bus-head">
            <img className="img-pc"  src={require('../../images/h0-1.jpeg')} style={{width: '100%'}} />
            <img className="img" src="https://img.gouxiang.cn/of/h0.jpeg" style={{width: '100%'}} />
            <div className="text">
              <div>我们的业务将从用户需求出发，</div>
              <div>真正创造价值，</div>
              <div>并不断降低成本，</div>
              <div>使更多人受益。</div>
            </div>
          </div>
          <div className="links">
            <Link to="/" title="我们"><div className="link">我们</div></Link>
            <div className="link active">业务</div>
            <Link to="/commitments" title="宗旨"><div className="link">宗旨</div></Link>
            <Link to="/insights" title="洞见"><div className="link">洞见</div></Link>
          </div>

          <div className="item">
            <div className="head">
              <img src={require('../../images/h1.png')} className="icon" />
              <div>
                <div className="title">构享<span style={{color: '#541f7e'}}>生活</span></div>
                <div className="desc eng">Gouxiang Life</div>
              </div>
            </div>
            <div className="text">
              <div>提供高品质的居住生活服务，包含</div>
              <div className="pc" style={{color: '#541f7e'}}>家居用品体验、保洁、维修、收纳整理、搬家、公寓租赁等服务。</div>
              <div className="mobile" style={{color: '#541f7e'}}>家居用品体验、保洁、维修、</div>
              <div className="mobile" style={{color: '#541f7e'}}>收纳整理、搬家、公寓租赁等服务。</div>
            </div>
            <img className="img" src={require('../../images/h2.jpeg')} style={{width: '100%'}} />
            <div className="img-pc">
              <img src={require('../../images/h2-1.jpeg')} style={{width: '100%'}} />
            </div>
          </div>

          <div className="item">
            <div className="head">
              <img src={require('../../images/h2.png')} className="icon" />
              <div>
                <div className="title">构享<span style={{color: '#541f7e'}}>家装</span></div>
                <div className="desc eng">Gouxiang</div>
                <div className="desc eng">Home Decoration</div>
              </div>
            </div>
            <div className="text">
              <div>为您的住宅提供</div>
              <div>全屋整装、翻新、家居配置等服务。</div>
            </div>
            <img className="img" src={require('../../images/h3.jpeg')} style={{width: '100%'}} />
            <div className="img-pc">
              <img src={require('../../images/h3-1.jpeg')} style={{width: '100%'}} />
            </div>
          </div>

          <div className="item">
            <div className="head">
              <img src={require('../../images/h3.png')} className="icon" />
              <div>
                <div className="title">构享<span style={{color: '#541f7e'}}>资管</span></div>
                <div className="desc eng">Gouxiang</div>
                <div className="desc eng">Assets Management</div>
              </div>
            </div>
            <div className="text">
              <div>提供房屋委托装修、出租、租务管理等</div>
              <div>一站式资产管理服务。</div>
            </div>
            <img className="img" src={require('../../images/h4.jpeg')} style={{width: '100%'}} />
            <div className="img-pc">
              <img src={require('../../images/h4-1.jpeg')} style={{width: '100%'}} />
            </div>
          </div>

          <div className="map" style={{position: 'relative', textAlign: 'center'}}>
            <div className="text">
              <div>构享基于对中国居住服务行业的理解和洞察，</div>
              <div>于重点城市开启业务并快速增长。</div>
              <div>未来，构享将开拓全国市场，</div>
              <div>布局中国核心城市群，</div>
              <div>为更多人带来更高品质的服务体验。</div>
            </div>
            <img src={require('../../images/h5.jpeg')} style={{width: '100%'}} />
          </div>
          <div className="map-pc" style={{position: 'relative', textAlign: 'center'}}>
            <div style={{maxWidth: 1200, padding: '0 174px', boxSizing: 'border-box', margin: '0 auto'}}>
              <div className="text">
                <div>构享基于对中国居住服务行业的理解和洞察，</div>
                <div>于重点城市开启业务并快速增长。</div>
                <div>未来，构享将开拓全国市场，</div>
                <div>布局中国核心城市群，</div>
                <div>为更多人带来更高品质的服务体验。</div>
              </div>
              <img src={require('../../images/h5-1.jpeg')} style={{width: '100%'}} />
            </div>
          </div>
        </div>
      </AppWrap>
    );
  }
}

export default Business;
